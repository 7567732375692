import {ACCESS_TOKEN} from "../constants/auth";
import {API_BASE_URL} from "../constants/api";
import axios from "axios";

const FormService = {};
FormService.saveForm = async function (applicationId) {
    let data = new FormData();
    data.append("applicationId", applicationId);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/admin/form/save",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

FormService.saveFormMultiple = async function (applicationIds) {
    let data = new FormData();
    data.append("applicationIds", applicationIds);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/admin/form/save/multiple",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

FormService.addIdCardFileToForm = async function (formId, fileId) {
    let data = new FormData();
    data.append("fileId", fileId);

    return axios({
        method: "post",
        url: API_BASE_URL + `/api/form/${formId}/add/file/idCard`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            // 'Authorization': `${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
FormService.addPreferentialFileToForm = async function (formId, fileId) {
    let data = new FormData();
    data.append("fileId", fileId);

    return axios({
        method: "post",
        url: API_BASE_URL + `/api/form/${formId}/add/file/preferential`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            // 'Authorization': `${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
FormService.addOtherFileToForm = async function (formId, fileId) {
    let data = new FormData();
    data.append("fileId", fileId);

    return axios({
        method: "post",
        url: API_BASE_URL + `/api/form/${formId}/add/file/other`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            // 'Authorization': `${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
FormService.deleteFileFromForm = async function (formId, fileId) {
    let data = new FormData();
    data.append("fileId", fileId);

    return axios({
        method: "post",
        url: API_BASE_URL + `/api/form/${formId}/delete/file`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            // 'Authorization': `${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

FormService.completeFormById = async function (formId) {

    return axios({
        method: "post",
        url: API_BASE_URL + `/api/form/${formId}/complete`,
        // headers: {
        //     'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        // }
    });
}

FormService.contractFormById = async function (formId) {

    return axios({
        method: "post",
        url: API_BASE_URL + `/api/admin/form/${formId}/contract`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

FormService.getFormById = async function (formId) {
    return axios({
        method: "get",
        url: API_BASE_URL + `/api/form/get/${formId}`,
        headers: {
            "Content-Type": "application/json"
        }
    });
}

FormService.updateFormById = async function (formId, formSave) {
    return axios({
        method: "put",
        url: API_BASE_URL + `/api/form/update/${formId}`,
        headers: {
            "Content-Type": "application/json"
        },
        data: formSave
    });
}

FormService.updateDiscountOfFormById = async function (formId, discount, discountName) {
    let data = new FormData();
    data.append("discount", discount);
    data.append("discountName", discountName);

    return axios({
        method: "post",
        url: API_BASE_URL + `/api/admin/form/update/${formId}/discount`,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        },
        data: data
    });
}

export default FormService;